import * as yup from 'yup';
import $ from 'jquery';
import { PATTERNS } from '../constants/patterns';
import { toHankaku } from '../util/string';
import { memberSchema } from './member-schema';
import { memberAgentAvailableSchema } from './member-agent-available-schema';
import { memberRequiredAgentAvailableSchema } from './member-required-agent-available-schema';
import { jobOfferSalarySchema } from './job-offer-salary-schema';
import { memberQualificationsAcquisitionScheduledSchema } from './member-qualifications-acquisition-scheduled-schema';
import { isPostalCode, isPostalCodeExist } from './postal-code';
import { memberQualificationsSchema } from './member-qualifications-schema';
import { memberRegistrationSchema } from './member-registration-schema';

const isEmailAlreadyRegistered = async (value) => {
  if (!value) {
    return true;
  }
  const response = await $.ajax({
    type: 'GET',
    url: '/api/users/members/exist_email',
    data: {
      'member[email]': value,
    },
  });
  if (response.data.status) {
    return false;
  }
  return true;
};

const hasBannedWords = (value) => {
  if (value.isDisabled) {
    return true;
  }

  const body = value.body;
  const converted = toHankaku(body);
  let res = [];

  if (converted) {
    res = (converted.match(PATTERNS.BANNED_WORDS) || []);
  }

  return !(res.length > 0);
};

const existResume = async (value) => {
  if (!value) {
    return true;
  }

  const response = await $.ajax({
    type: 'GET',
    url: '/api/users/resumes/exist',
    data: {
      member_resume_id: value,
    },
  });

  if (response.status) {
    return true;
  }
  return false;
};

const existCareerSheet = async (value) => {
  if (!value) {
    return true;
  }

  const response = await $.ajax({
    type: 'GET',
    url: '/api/users/careersheets/exist',
    data: {
      member_careersheet_id: value,
    },
  });

  if (response.status) {
    return true;
  }
  return false;
};

const calcAcademicPeriodDate = (y, m, d) => {
  let year = +y;
  const month = +m;
  const day = +d;

  if (month < 4 || (month === 4 && day === 1)) {
    year -= 1;
  }

  // YYYYMMDD
  return (year * 10000) + 400 + 1;
};

const newMemberSchema = {
  'member[email]': {
    validation: yup
      .string()
      .required('メールアドレスを入力してください')
      .max(100, 'メールアドレスは100文字以内で入力してください')
      .matches(PATTERNS.MAIL_ADDRESS, '正しいメールアドレスを入力してください')
      .when('$eventType', (eventTypeName, schema) => {
        return eventTypeName === 'submit'
          ? schema.test('is-already-registered-email', '登録済みのメールアドレスです', isEmailAlreadyRegistered)
          : schema;
      }),
    count: true,
  },

  'member[password]': {
    validation: yup
      .string()
      .required('パスワードを入力してください')
      .min(8, '8文字以上のパスワードを入力してください'),
    count: true,
  },
};

const schema = {
  login: {
    'users2016_member[email]': {
      validation: yup
        .string()
        .required('メールアドレスを入力してください')
        .matches(PATTERNS.MAIL_ADDRESS, 'メールアドレスを入力してください'),
    },

    'users2016_member[password]': {
      validation: yup
        .string()
        .required('パスワードを入力してください'),
    },
  },

  'search-condition': {
    title: {
      validation: yup
        .string()
        .required('検索条件名を入力してください')
        .max(100, '100文字以内で入力してください'),
    },
  },

  registration: {
    ...memberRegistrationSchema,
    ...newMemberSchema,
  },

  registrationWithAgent: {
    ...memberRegistrationSchema,
    ...memberAgentAvailableSchema,
    ...newMemberSchema,
  },

  application: {
    ...memberSchema,
    ...newMemberSchema,
    ...jobOfferSalarySchema,
    ...memberQualificationsAcquisitionScheduledSchema,
  },

  agentApplication: {
    ...memberSchema,
    ...newMemberSchema,
    ...memberRequiredAgentAvailableSchema,
    ...jobOfferSalarySchema,
    ...memberQualificationsAcquisitionScheduledSchema,
  },

  applicationWithQualificationValidation: {
    ...memberSchema,
    ...newMemberSchema,
    ...jobOfferSalarySchema,
    ...memberQualificationsSchema,
    ...memberQualificationsAcquisitionScheduledSchema,
  },

  'password-reset': {
    'users2016_member[email]': {
      validation: yup
        .string()
        .required('メールアドレスを入力してください')
        .matches(PATTERNS.MAIL_ADDRESS, 'メールアドレスを入力してください'),
    },
  },
  'password-renew': {
    'users2016_member[password]': {
      validation: yup
        .string()
        .required('パスワードを入力してください')
        .min(8, '8文字以上のパスワードを入力してください'),
    },
    'users2016_member[password_confirmation]': {
      getValue: ($form) => {
        return {
          password: $form.find('input[name="users2016_member[password]"]').val(),
          passwordConfirm: $form.find('input[name="users2016_member[password_confirmation]"]').val(),
        };
      },
      validation: yup
          .object({
            passwordConfirm: yup
              .string()
              .required('パスワードを入力してください')
              .min(8, '8文字以上のパスワードを入力してください'),
          })
          .test('is-not-equal', '入力したパスワードが一致していません', (value) => {
            return value.password === value.passwordConfirm;
          }),
    },
  },
  message: {
    'message[body]': {
      getValue: ($form) => {
        return {
          isDisabled: $form.find('textarea[name="message[body]"]').data('disable-banned-words'),
          body: $form.find('textarea[name="message[body]"]').val(),
        };
      },
      validation: yup
        .object({
          body: yup
            .string()
            .required('本文を入力してください'),
        })
        .test('has-banned-words', '本文の中に電話番号やURL・メールアドレスを含めることはできません', hasBannedWords),
    },
    'message[message_files][file][]': {
      getValue: ($form) => {
        const $fileTargetElement = $form.find('.js-attach-files');
        const $fileInput = $form.find('.js-form-manager__field--applicant_photos');
        if ($fileTargetElement.length < 1) {
          return true;
        }
        if ($fileInput.length < 1) {
          return null;
        }
        if (!$fileInput[0].files || $fileInput[0].files?.length < 1) {
          return null;
        }
        const $files = [$fileInput[0].files[0], $fileInput[1].files[0], $fileInput[2].files[0]];
        return $files;
      },
      validation: yup
        .mixed()
        .nullable()
        .test('is-limit-over', '10MB以下のファイルを選択してください', ($files) => {
          if ($files[0] != null && $files[0].size > 10485760) {
            return false;
          }
          if ($files[1] != null && $files[1].size > 10485760) {
            return false;
          }
          if ($files[2] != null && $files[2].size > 10485760) {
            return false;
          }
          return true;
        }),
    },
  },
  attachDocuments: {
    select_message_resume_id: {
      validation: yup
        .string()
        .test('exist-resume', '履歴書の作成に失敗しました。履歴書の再編集または複製を行い、「履歴書の作成を完了する」ボタンを押してください。', existResume),
    },
    select_message_careersheet_id: {
      validation: yup
        .string()
        .test('exist-careersheet', '職務経歴書が存在しません', existCareerSheet),
    },
  },
  inquiry: {
    'inquiry[family_name]': {
      validation: yup
        .string()
        .trim()
        .required('名字を入力してください')
        .max(50, '名字は50文字以内で入力してください'),
    },

    'inquiry[first_name]': {
      validation: yup
        .string()
        .trim()
        .required('名前を入力してください')
        .max(50, '名前は50文字以内で入力してください'),
    },

    'inquiry[family_name_kana]': {
      validation: yup
        .string()
        .required('名字（ひらがな）を入力してください')
        .max(50, '名字（ふりがな）は50文字以内で入力してください')
        .matches(PATTERNS.HIRAGANA, '名字（ふりがな）はひらがなで入力してください'),
    },

    'inquiry[first_name_kana]': {
      validation: yup
        .string()
        .required('名前（ひらがな）を入力してください')
        .max(50, '名前（ふりがな）は50文字以内で入力してください')
        .matches(PATTERNS.HIRAGANA, '名前（ふりがな）はひらがなで入力してください'),
    },

    'inquiry[corporation_name]': {
      validation: yup
        .string()
        .max(100, '法人名・団体名は100文字以内で入力してください'),
    },

    'inquiry[tel]': {
      validation: yup
        .string()
        .transform(v => (!v ? undefined : v))
        .matches(PATTERNS.TEL, '正しい電話番号を入力してください'),
    },

    'inquiry[email]': {
      validation: yup
        .string()
        .required('メールアドレスを入力してください')
        .max(100, 'メールアドレスは100文字以内で入力してください')
        .matches(PATTERNS.MAIL_ADDRESS, '正しいメールアドレスを入力してください'),
    },

    'inquiry[body]': {
      validation: yup
        .string()
        .trim()
        .required('お問い合わせ内容を入力してください')
        .max(4000, 'お問い合わせ内容は4000文字以内で入力してください'),
    },
  },
  landingPageChat: {
    ...memberSchema,
    ...newMemberSchema,

    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },

    'member[qualifications][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-qualification-id:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[qualifications][]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', 'お持ちの資格を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  landingPageGeneralStep: {
    ...memberSchema,
    ...memberAgentAvailableSchema,
    ...newMemberSchema,

    'member[job_category_ids][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-category-id:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[job_category_ids][]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望職種を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  landingPageGeneralBookletStep: {
    ...memberSchema,
    ...newMemberSchema,

    'member[job_category_ids][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-category-id:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[job_category_ids][]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望職種を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },

    'member[employment_status]': {
      validation: yup
        .number(),
      count: false,
    },
  },
  landingPageChatClerk: {
    ...memberSchema,
    ...newMemberSchema,

    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },

    'member[job_category_ids][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-category-id:checked').length;
      },
      validation: yup
        .string()
        .test('required', '希望職種を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  landingPageStepClerk: {
    ...memberSchema,
    ...newMemberSchema,

    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },

    'member[job_category_ids][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-category-id:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[job_category_ids][]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望職種を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  landingPagePfi: {
    ...memberSchema,
    ...newMemberSchema,

    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },

    'member[job_category_ids][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-category-id:checked').length;
      },
      validation: yup
        .string()
        .test('required', '希望職種を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  landingPageAgentChat: {
    ...memberSchema,
    ...newMemberSchema,

    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  incomeDiagnosisStep1: {
    'member[postal_code]': {
      validation: yup
        .string()
        .notRequired()
        .test('is-invalid-postal-code', '正しい郵便番号を入力してください', isPostalCode)
        .test('is-postal-code-exist', '郵便番号が見つかりません', isPostalCodeExist),
    },
    'member[prefecture_id]': {
      validation: yup
        .string()
        .required('都道府県を選択してください'),
      count: true,
    },
    'member[city_id]': {
      validation: yup
        .string()
        .required('市区町村を選択してください'),
      count: true,
    },
  },
  incomeDiagnosisStep2: {
    'member[desired_job_category_id]': {
      validation: yup
        .string()
        .required('職種を選択してください'),
      count: true,
    },
  },
  incomeDiagnosisStep3: {
    'member[experience]': {
      getValue: ($form) => {
        const value = $form.find('[name="member[experience]"]:checked').val();

        if (value === undefined) {
          return 0;
        }

        return +value || 0;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[experience]"]').siblings('label');
      },
      validation: yup
        .number()
        .min(1, '経験年数を選択してください'),
      count: true,
    },
  },
  incomeDiagnosisStep4: {
    'member[salary_yearly]': {
      validation: yup
        .number()
        .required('適切な値を入力してください')
        .transform(v => (isNaN(v) ? undefined : v))
        .max(99999999, '適切な値を入力してください'),
    },
  },
  incomeDiagnosisStep5: {
    'member[procedure]': {
      validation: yup
        .string()
        .required('項目から選択してください'),
      count: true,
    },
  },
  incomeDiagnosisStep6: {
    'member[big_failure]': {
      validation: yup
        .string()
        .required('項目から選択してください'),
      count: true,
    },
  },
  incomeDiagnosisStep7: {
    'member[challenging]': {
      validation: yup
        .string()
        .required('項目から選択してください'),
      count: true,
    },
  },
  incomeDiagnosisEntry: {
    ...newMemberSchema,

    'member[family_name]': {
      validation: yup
        .string()
        .trim()
        .required('名字を入力してください')
        .max(50, '名字は50文字以内で入力してください'),
      count: true,
    },

    'member[first_name]': {
      validation: yup
        .string()
        .trim()
        .required('名前を入力してください')
        .max(50, '名前は50文字以内で入力してください'),
      count: true,
    },

    'member[family_kana]': {
      validation: yup
        .string()
        .required('名字（ひらがな）を入力してください')
        .max(50, '名字（ふりがな）は50文字以内で入力してください')
        .matches(PATTERNS.HIRAGANA, '名字（ふりがな）はひらがなで入力してください'),
      count: true,
    },

    'member[first_kana]': {
      validation: yup
        .string()
        .required('名前（ひらがな）を入力してください')
        .max(50, '名前（ふりがな）は50文字以内で入力してください')
        .matches(PATTERNS.HIRAGANA, '名前（ふりがな）はひらがなで入力してください'),
      count: true,
    },

    'member[birthday(1i)]': {
      validation: yup
        .string()
        .required('生年月日を選択してください'),
      count: true,
    },

    'member[birthday(2i)]': {
      validation: yup
        .string()
        .required('生年月日を選択してください'),
      count: true,
    },

    'member[birthday(3i)]': {
      getValue: ($form) => {
        const $birthdays = $form.find('.js-form-manager__field--birthday');
        return {
          birthday: [...$birthdays].map(field => field.value),
          minAge: +($birthdays.last().data('form-manager-min-age')),
        };
      },
      getDestination: $form => $form.find('.js-form-manager__field--birthday'),
      validation: yup
        .object()
        .test('is-fulfilled-birthday', '生年月日を選択してください', (value) => {
          const { birthday } = value;
          const y = birthday[0];
          const m = birthday[1];
          const d = birthday[2];

          if (!y || !m || !d) {
            return false;
          }
          return true;
        })
        .test('is-valid-birthday', '応募条件を満たす年齢に達していません', (value) => {
          const { birthday, minAge } = value;
          if (!minAge) {
            return true;
          }
          const y = birthday[0];
          const m = birthday[1];
          const d = birthday[2];
          const birthPeriod = calcAcademicPeriodDate(y, m, d);
          const now = new Date();
          const jobPeriod = calcAcademicPeriodDate((now.getFullYear() + 1) - (minAge + 1), now.getMonth() + 1, now.getDate());

          return birthPeriod <= jobPeriod;
        }),
      count: true,
    },
    'member[tel]': {
      validation: yup
        .string()
        .required('電話番号を入力してください')
        .matches(PATTERNS.TEL, '正しい電話番号を入力してください'),
      count: true,
    },

    'member[gender]': {
      getValue: ($form) => {
        const value = $form.find('[name="member[gender]"]:checked').val();

        if (value === undefined) {
          return 0;
        }

        return +value || 0;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[gender]"]').siblings('label');
      },
      validation: yup
        .number()
        .min(1, '性別を選択してください'),
      count: true,
    },
    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .test('required', '希望勤務形態を選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
    'member[employment_status]': {
      getValue: ($form) => {
        const value = $form.find('[name="member[employment_status]"]:checked').val();

        if (value === undefined) {
          return 0;
        }

        return +value || 0;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[employment_status]"]').siblings('label');
      },
      validation: yup
        .number()
        .min(1, '就業状況を選択してください'),
      count: true,
    },
  },
  celebration: {
    'user_voice[answers_attributes][0][body][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-user-voice-answer:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="user_voice[answers_attributes][0][body][]"]').siblings('label');
      },
      validation: yup
        .string()
        .test('required', '一つ以上選択してください', (checkedLength) => {
          return checkedLength > 0;
        }),
    },
    'user_voice[hiring_date(1i)]': {
      validation: yup
        .string()
        .required('入職日を選択してください'),
    },
    'user_voice[hiring_date(2i)]': {
      validation: yup
        .string()
        .required('入職日を選択してください'),
    },
    'user_voice[hiring_date(3i)]': {
      getValue: ($form) => {
        const $hiringDates = $form.find('.js-form-manager__field--hiring-date');
        return {
          hiringDates: [...$hiringDates].map(field => field.value),
        };
      },
      getDestination: $form => $form.find('.js-form-manager__field--hiring-date'),
      validation: yup
        .object()
        .test('is-valid-hiring-date', '入職日を選択してください', (value) => {
          const {
            hiringDates,
          } = value;
          const y = hiringDates[0];
          const m = hiringDates[1];
          const d = hiringDates[2];

          if (!y || !m || !d) {
            return false;
          }
          return true;
        }),
    },
    // 現状ロジックから100文字のパターンのみしか無いのでそのパターンのみ作る
    user_voice_min_free_text: {
      getStyleTargetElement: ($form) => {
        return $form.find('.js-form-manager__field--user-voice-free-text');
      },
      getDestination: $form => $form.find('.js-form-manager__field--user-voice-free-text'),
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--user-voice-free-text').val();
      },
      validation: yup
        .string()
        .trim()
        .required('100文字以上入力してください')
        .min(100, '100文字以上入力してください'),
    },
    'user_voice[rating]': {
      validation: yup
        .string()
        .required('満足度を選択してください'),
    },
    'bank_account[bank_code]': {
      validation: yup
        .string()
        .required('銀行コードを入力してください')
        .matches(/\d*/, '数字を入力してください')
        .matches(PATTERNS.BANK_CODE, '半角数字4桁で入力してください'),
    },
    'bank_account[branch_code]': {
      validation: yup
        .string()
        .required('支店コードを入力してください')
        .matches(/\d*/, '数字を入力してください')
        .matches(PATTERNS.BANK_BRANCH_CODE, '半角数字3桁で入力してください'),
    },
    'bank_account[deposit_type]': {
      validation: yup
        .string()
        .required('預金種目を選択してください'),
    },
    'bank_account[account_number]': {
      validation: yup
        .string()
        .required('口座番号を入力してください')
        .matches(/\d*/, '数字を入力してください')
        .matches(PATTERNS.BANK_ACCOUNT_CODE, '半角数字7桁で入力してください'),
    },
    'bank_account[account_family_name]': {
      validation: yup
        .string()
        .required('口座名義(セイ)を入力してください')
        .matches(PATTERNS.BANK_ACCOUNT_NAME, '全角カナで入力してください')
        .max(24, '24文字以内で入力してください'),
    },
    'bank_account[account_first_name]': {
      validation: yup
        .string()
        .required('口座名義(メイ)を入力してください')
        .matches(PATTERNS.BANK_ACCOUNT_NAME, '全角カナで入力してください')
        .max(24, '24文字以内で入力してください'),
    },
  },
  'settings-notification': {},
  'settings-support': {},
  'settings-agent-available': {},
  'settings-email': {
    'member[email]': {
      validation: yup
        .string()
        .required('メールアドレスを入力してください')
        .matches(PATTERNS.MAIL_ADDRESS, '正しいメールアドレスを入力してください')
        .max(100, 'メールアドレスは100文字以内で入力してください')
        .test('is-already-registered-email', '登録済みのメールアドレスです', isEmailAlreadyRegistered),
    },
  },
  'settings-password': {
    'member[current_password]': {
      validation: yup
        .string()
        .required('現在のパスワードを入力してください'),
    },
    'member[password]': {
      validation: yup
        .string()
        .required('パスワードを入力してください')
        .min(8, '8文字以上のパスワードを入力してください'),
    },
    'member[password_confirmation]': {
      getValue: ($form) => {
        return {
          password: $form.find('input[name="member[password]"]').val(),
          passwordConfirm: $form.find('input[name="member[password_confirmation]"]').val(),
        };
      },
      validation: yup
        .object({
          passwordConfirm: yup
            .string()
            .required('パスワードを入力してください')
            .min(8, '8文字以上のパスワードを入力してください'),
        })
        .test('is-not-equal', '入力したパスワードが一致していません', (value) => {
          return value.password === value.passwordConfirm;
        }),
    },
  },
  'indeed-finish-application-email-password': {
    'member[email]': {
      getValue: ($form) => {
        return {
          currentEmail: $form.find('input[name="member[current_email]"]').val(),
          email: $form.find('input[name="member[email]"]').val(),
        };
      },
      validation: yup
        .object({
          email: yup
            .string()
            .required('メールアドレスを入力してください')
            .matches(PATTERNS.MAIL_ADDRESS, '正しいメールアドレスを入力してください')
            .matches(/^(?!.*@privaterelay\.appleid\.com).*$/i, 'Appleサインインでメールアドレスを非公開に設定した際に登録されるアドレス（〜@privaterelay.appleid.com）の場合、ジョブメドレーからのメールが届かないため、送受信可能なメールアドレスを入力してください。')
            .matches(/^(?!.*@qq\.com).*$/i, 'ご利用いただけないドメインのメールアドレスです。別のメールアドレスを入力してください')
            .max(100, 'メールアドレスは100文字以内で入力してください'),
        })
        .test('is-already-registered-email', '登録済みのメールアドレスです', (value) => {
          if (value.currentEmail === value.email) {
            return true;
          }
          return isEmailAlreadyRegistered(value.email);
        }),
    },
    'member[password]': {
      validation: yup
        .string()
        .required('パスワードを入力してください')
        .min(8, '8文字以上のパスワードを入力してください'),
    },
  },
  'profile-basic': async () => {
    const { ProfileBasicSchema } = await import('./profile-basic-schema');
    return new ProfileBasicSchema().buildSchema();
  },
  'profile-education-background': async () => {
    const { ProfileEducationBackgroundSchema } = await import('./profile-education-background-schema');
    return new ProfileEducationBackgroundSchema().buildSchema();
  },
  'profile-career': async () => {
    const { ProfileCareerSchema } = await import('./profile-career-schema');
    return new ProfileCareerSchema().buildSchema();
  },
  'profile-desire': async () => {
    const { ProfileDesireSchema } = await import('./profile-desire-schema');
    return new ProfileDesireSchema().buildSchema();
  },
  'profile-additional-member-qualification': async () => {
    const { ProfileAdditionalMemberQualificationSchema } = await import('./profile-additional-member-qualification-schema');
    return new ProfileAdditionalMemberQualificationSchema().buildSchema();
  },
  'profile-additional-desired-job-category': async () => {
    const { ProfileAdditionalDesiredJobCategorySchema } = await import('./profile-additional-desired-job-category-schema');
    return new ProfileAdditionalDesiredJobCategorySchema().buildSchema();
  },
  'profile-additional-member-gender': async () => {
    const { ProfileAdditionalMemberGenderSchema } = await import('./profile-additional-member-gender-schema');
    return new ProfileAdditionalMemberGenderSchema().buildSchema();
  },
  'profile-additional-member-employment-status': async () => {
    const { ProfileAdditionalMemberEmploymentStatusSchema } = await import('./profile-additional-member-employment-status-schema');
    return new ProfileAdditionalMemberEmploymentStatusSchema().buildSchema();
  },
  'profile-additional-desired-employment-types': async () => {
    const { ProfileAdditionalDesiredEmploymentTypesSchema } = await import('./profile-additional-desired-employment-types-schema');
    return new ProfileAdditionalDesiredEmploymentTypesSchema().buildSchema();
  },
  'resume-dynamic-schemas': async () => {
    const { ResumeDynamicSchema } = await import('./resume-dynamic-schema');
    return new ResumeDynamicSchema().buildSchema();
  },
  'resume-index': async () => {
    const { ResumeIndexSchema } = await import('./resume-index-schema');
    return new ResumeIndexSchema().buildSchema();
  },
  'resume-basic': async () => {
    const { ResumeBasicSchema } = await import('./resume-basic-schema');
    return new ResumeBasicSchema().buildSchema();
  },
  'resume-reason-for-applying': async () => {
    const { ResumeReasonForApplyingSchema } = await import('./resume-reason-for-applying-schema');
    return new ResumeReasonForApplyingSchema().buildSchema();
  },
  'resume-others': async () => {
    const { ResumeOthersSchema } = await import('./resume-others-schema');
    return new ResumeOthersSchema().buildSchema();
  },
  'resume-displayed-created-at': async () => {
    const { ResumeDisplayedCreatedAtSchema } = await import('./resume-displayed-created-at-schema');
    return new ResumeDisplayedCreatedAtSchema().buildSchema();
  },
  'member-careersheet': async () => {
    const { MemberCareersheetSchema } = await import('./member-careersheet-schema');
    return new MemberCareersheetSchema().buildSchema();
  },
  'violation-report': async () => {
    const { ViolationReportSchema } = await import('./violation-report-schema');
    return new ViolationReportSchema().buildSchema();
  },
};

export {
  schema,
};
